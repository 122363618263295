:root {
  --primary-color: #febe11;
  --secondary-color: #000000;
  --text-white-color: white;
}

/* font family  */

@font-face {
  font-family: "JumboOmeHenkText";
  src: url("/public/assets/fonts/JumboOmeHenkText.otf");
}

@font-face {
  font-family: "JumboMindset-Slim";
  src: url("/public/assets/fonts/JumboMindset-Slim.otf");
}

@font-face {
  font-family: "JumboMindset-Regular";
  src: url("/public/assets/fonts/JumboMindset-Regular.otf");
}

@font-face {
  font-family: "Jumbo-TheSans-Black";
  src: url("/public/assets/fonts/Jumbo-TheSans-Black.ttf");
}

@font-face {
  font-family: "Jumbo-TheSans-Regular";
  src: url("/public/assets/fonts/Jumbo-TheSans-Regular.ttf");
}


.f-family-Jumbo-TheSans-Regular {
  font-family: "Jumbo-TheSans-Regular";
}

.f-family-Jumbo-TheSans-Black {
  font-family: "Jumbo-TheSans-Black";
}

.f-family-JumboOmeHenkText {
  font-family: "JumboOmeHenkText";
}

.f-family-JumboMindset-Slim {
  font-family: "JumboMindset-Slim";
}

.f-famalu-JumboMindset-Regular {
  font-family: "JumboMindset-Regular";
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: JumboMindset-Regular;

}

/* --text formting -- */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}


.main-wrapper {
  /* background-image: url('/public/images/background.jpg');
  background-size: cover;
  background-repeat: no-repeat; */
  font-size: calc(10px + 2vmin);
  height: 100%;
  background: #EEB717;
}

.inner-section {
  height: 100vh;
  overflow: auto;
  padding-top: 15px;
}


/* ========KioskStart.js======== */

.logo img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.sub-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 550px;
}

.handarrow-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 131px;
}


/* =======KioskTicket.js======== */

.eye-logo {
  display: block;
  margin-left: auto;
  margin-top: 10vh;
  margin-right: auto;
  width: 33%;
}

.water-melon-logo {
  display: block;
  margin-top: 3vh;
  margin-left: auto;
  margin-right: auto;
  width: 340px;
  position: relative;
  z-index: 3;
}

.div-img-width::after {
  content: "";
  background: url("/public/images/shadow.png");
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 400px;
  height: 184px;
  z-index: 1;
  right: -30px;
  bottom: -10px;
}

.content1 {
  position: relative;
}


/* =======KioskTicket.js======== */
.arrow {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 10%;
}

.scan-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 890px;
  object-fit: cover;
  position: relative;
  z-index: 2;
}

.content2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.hand-logo {
  margin-right: 20px;
}

.handscan-logo img {
  max-width: 100%;
  /* float: left; */
}

.instructions {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* align-items: center; */
  margin-bottom: 20px;
}

.instruction {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: bold;
  color: black;
  font-family: JumboOmeHenkText;
}

.number {
  background-color: black;
  border-radius: 50%;
  margin-right: 10px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-family: 'JumboOmeHenkText';
  color: #fbe14a;
}

.video-tag-starting {
  height: 100vh;
  width: 100%;
}

/* .inner-section-kiosk {
  height: 100vh;
  overflow: auto;
  padding-top: 3em;
} */

.inner-section-kiosk::-webkit-scrollbar {
  display: none;
}

.scan-logo-heading {
  width: 75%;
  margin: auto;
}

.scanner-div {
  position: absolute;
  top: 0;
}

.inner-section-scanner {
  height: 100vh;
  overflow: auto;
  padding-top: 15px;
}

.scanner-div {
  position: absolute;
  top: 165px;
  width: 220px;
  left: 128px;
  transform: rotate(-1deg);
}

.instructions-sec {
  display: flex;
  align-items: center;
  margin-left: -70px;
}

.content-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-64 {
  width: 64%;
}

.text-qr {
  font-size: 35px;
  font-family: JumboOmeHenkText;
  font-weight: 200;

}


.text-succ {
  font-size: 36px;
  color: black;
  font-family: Jumbo-TheSans-Black;
  text-align: center;
  margin-bottom: 1.5em;
}

.padding-zero {
  padding: 0;
}

.succ-img-div {
  padding: 50px 15px;
  background: white;
  border-radius: 20px;
}

.success-content-sec {
  margin-left: -45px;
  padding-right: 25px;
}

.fail-content-sec {
  margin-left: -100px;
}

.inner-section-ticket {
  height: 100vh;
  padding-top: 1em;
  overflow: auto;
}

.thankx-img {
  width: 550px;
  margin: auto;
}

.scna-div {
  width: 520px;
}

.scan-row {
  width: 100%;
}

.inner-fail-sec {
  height: 100vh;
  overflow: auto;
}

.div-img-width {
  width: 335px;
  margin: auto;

}

.logo-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-img {
  max-width: 650px;
  margin: auto;
  width: 100%;
}

.gayer-div {
  float: right;
  margin-right: 15px;
}


.print_status_dot {
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

.printer_status {
  position: fixed;
  bottom: 0;
  left: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'JumboOmeHenkText';
  font-size: 20px;
  letter-spacing: 2px;
}

.reconnect_button {
  font-family: 'JumboOmeHenkText';
  border-width: 6px;
  border-color: white;
  background-color: black;
  display: inline-block;
  padding: 8px;
  color: white;
  border-radius: 16px;
}

.CONNECTING {
  background: blue;
  box-shadow: 0 0 2px 1px blue;
}

.CONNECTED {
  background: green;
  box-shadow: 0 0 2px 1px green;
}

.DISCONNECTED {
  background: black;
  box-shadow: 0 0 2px 1px black;
}

.FAILED_TO_CONNECT {
  background: red;
  box-shadow: 0 0 2px 1px red;
}

.PRINTING {
  bacground: yellow;
  box-shadow: 0 0 2px 1px yellow;
}


@media only screen and (min-width: 992px) {
  .scan-logo-heading {
    width: 60%;
    overflow: hidden;
  }

  .arrow {
    width: 8%;
  }

  .scan-row {
    width: auto;
  }

  .scna-div {
    width: 700px;
  }

  .scanner-div {
    position: absolute;
    top: 16%;
    width: 226px;
    left: 26.4%;
  }

  .scan-logo {
    height: 78vh;
  }

  .fail-phone {
    height: 80vh;
  }
}

@media only screen and (min-width: 1200px) {
  .arrow {
    width: 80px;
  }


  .scan-logo-heading {
    width: 625px;
  }

  .scanner-div {
    /* position: absolute;
    top: 250px;
    width: 224px;
    left: 175px; */

  }

}

@media only screen and (min-width: 1500px) {}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {}

@media only screen and (min-width: 993px) and (max-width: 1199px) {}




.qr-reader {
  width: 21vh !important;
  height: 43vh !important;
}

.qr-frame {
  width: 100%;
}

@media only screen and (max-width: 820px) {
  .scan-logo {
    height: 70vh;
  }

  .main-container {
    max-width: 100%;
  }

  .number {
    width: 50px;
    height: 50px;
    font-size: 25px;
  }

  .text-qr {
    font-size: 30px;
  }

  .scanner-div {
    top: 18.4%;
    width: 145px;
    left: 26.4%;
  }

  .qr-reader {
    width: 26vw !important;
    height: 34vh !important;
  }


}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .main-container {
    max-width: 100%;
  }
}


.loader-printer {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: brightness(0.5);
}

.loader-print {
  width: 150px;
  height: 150px;
}

.btm-img {
  width: 30%;
}

.btm-img-div {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  text-align: center;
}

.right-img::before {
  content: "";
  background: url(/public/images/checkgreen.png);
  /* background-color: aqua; */
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 170px;
  height: 146px;
  top: 35%;
  left: 35%;
  z-index: 9999;
}

.wrong-img::before {
  content: "";
  background: url(/public/images/wrongimg.png);
  /* background-color: aqua; */
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 170px;
  height: 146px;
  top: 35%;
  left: 35%;
  z-index: 9999;
}


.prize-head {
  font-size: 10vw;
  color: black;
  line-height: 1;
}

.prize-name {
  font-size: 5.7vw;
  color: black;
}

.head-div {
  gap: 15px;
}

.btm-logo-img {
  width: 42vw;
}

.btm-img-food {
  width: 15vw;
}

.align-items-center {
  gap: 15px;
  margin-bottom: 24vh;
}

.prize-content-sec {
  margin-top: 10vh;
  text-align: center;
}

.heading-section.text-center {
  padding-top: 2em;
}